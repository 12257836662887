import { getPathStringWithoutLocale } from ".";

enum SESSION_KEY {
  PREV_LOCATION = "prevLocation",
  CURRENT_LOCATION = "currentLocation",
}

const getPrevLocation = () => sessionStorage.getItem(SESSION_KEY.PREV_LOCATION);

const updatePrevLocation = (value: string) =>
  sessionStorage.setItem(SESSION_KEY.PREV_LOCATION, value);

const getCurrentLocation = () => sessionStorage.getItem(SESSION_KEY.CURRENT_LOCATION);

const updateCurrentLocation = (value: string) =>
  sessionStorage.setItem(SESSION_KEY.CURRENT_LOCATION, value);

const updateLocation = (pathname: string) => {
  const pathnameWithoutLocale = getPathStringWithoutLocale(pathname);

  const currentLocation = getCurrentLocation();
  if (!currentLocation) {
    updateCurrentLocation(pathnameWithoutLocale);
    return;
  }

  const isSamePage = currentLocation === pathnameWithoutLocale;
  const prevLocation = getPrevLocation();
  if (prevLocation && isSamePage) {
    return;
  }

  updateCurrentLocation(pathnameWithoutLocale); // for checking pathname
  updatePrevLocation(currentLocation); // used as slug
};

export { getPrevLocation, getCurrentLocation, updateLocation };
