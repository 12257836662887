require("./src/styles/global.css");
require("@fontsource/mulish");
require("@fontsource/mulish/200.css");
require("@fontsource/mulish/300.css");

const { updateLocation } = require("./src/utils/url/prevLocation");

exports.onRouteUpdate = ({ location }) => {
  updateLocation(location.pathname);
};
