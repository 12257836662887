import * as ConfigLocales from "website-metadata";

export const LANGUAGE_CODE = ConfigLocales.LANGUAGE_CODE;
export const LANGUAGE_FORMATTED_LIST = ConfigLocales.LANGUAGE_FORMATTED_LIST;
export const LANGUAGE_MAP_SET = ConfigLocales.LANGUAGE_MAP_SET;
export const DEFAULT_LANGUAGE = ConfigLocales.DEFAULT_LANGUAGE;

export const MAPPED_LANG_TO_FORMATTED_LOCALE = {
  [LANGUAGE_CODE.EN]: "en-US",
  [LANGUAGE_CODE.TW]: "zh-TW",
  [LANGUAGE_CODE.JP]: "ja-JP",
  [LANGUAGE_CODE.KO]: "ko-KR",
};

export const MAPPED_LANG_TO_OPEN_GRAPH = {
  [LANGUAGE_CODE.EN]: "en_US",
  [LANGUAGE_CODE.TW]: "zh_TW",
  [LANGUAGE_CODE.JP]: "ja_JP",
  [LANGUAGE_CODE.KO]: "ko_KR",
};
