import { LANGUAGE_MAP_SET } from "constants/i18n";
import type { LANGUAGE_CODE } from "website-metadata";

const urlConcat = (splitted: string[]) => splitted.reduce((acc, val) => `${acc}/${val}`, "");

const getPathStringWithoutLocale = (path: string) => {
  const splittedUrl = path.split("/").filter((it) => it);

  const possibleLocaleCode = splittedUrl[0];

  const isPathHasLocale =
    possibleLocaleCode && LANGUAGE_MAP_SET.has(possibleLocaleCode as LANGUAGE_CODE);

  if (isPathHasLocale) {
    const [, ...path] = splittedUrl;
    const urlWithoutLocale = urlConcat(path);
    return urlWithoutLocale || "/";
  }

  return path;
};

export { getPathStringWithoutLocale };
