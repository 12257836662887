exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-background-tsx": () => import("./../../../src/pages/careers/background.tsx" /* webpackChunkName: "component---src-pages-careers-background-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-relations-index-tsx": () => import("./../../../src/pages/investor-relations/index.tsx" /* webpackChunkName: "component---src-pages-investor-relations-index-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-background-tsx": () => import("./../../../src/pages/products/background.tsx" /* webpackChunkName: "component---src-pages-products-background-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-keelung-index-tsx": () => import("./../../../src/pages/products/keelung/index.tsx" /* webpackChunkName: "component---src-pages-products-keelung-index-tsx" */),
  "component---src-pages-products-kenting-index-tsx": () => import("./../../../src/pages/products/kenting/index.tsx" /* webpackChunkName: "component---src-pages-products-kenting-index-tsx" */),
  "component---src-pages-products-pqscale-index-tsx": () => import("./../../../src/pages/products/pqscale/index.tsx" /* webpackChunkName: "component---src-pages-products-pqscale-index-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-article-post-tsx": () => import("./../../../src/templates/articlePost.tsx" /* webpackChunkName: "component---src-templates-article-post-tsx" */),
  "component---src-templates-job-detail-tsx": () => import("./../../../src/templates/jobDetail.tsx" /* webpackChunkName: "component---src-templates-job-detail-tsx" */)
}

